import React, {useState} from 'react'
import Navegador from './Navegador'
import { useNavigate } from "react-router-dom";
import FormPerson from '../personas2/FormPerson';

function Home() {
  const navegador = useNavigate();
  
  const usuarioActivo = JSON.parse(localStorage.getItem("usuarioActivo"));
  
  if(usuarioActivo && usuarioActivo.rol !== "")
    console.log(localStorage.getItem("usuarioActivo"))
  else
      navegador("/login");
   
const [persona, setPersona] = useState(null)
const [personaN, setPersonaN] = useState(null)
  //console.log(localStorage.getItem("rol"))
  //if(localStorage.getItem("rol") === undefined)
    //navegador("/login");
const asignarPersona = (person) => {
  console.log(person)
  setPersona(person)
}

const limpiar = () => {
  setPersonaN({
    _id: null, 
    nombres: "", 
    apellidos: "", 
    tipDoc: "CC", 
    numDoc: "",
    direccion: "", 
    telefono: "",
    correo: ""
})
}

const mostrar = () => {console.log(persona)}
  return (<div>
    <Navegador/>
    <FormPerson setPerson={personaN} getPerson={asignarPersona}/>

    <button onClick={mostrar}>Persona</button>
    <button onClick={limpiar}>Limpiar</button>
  </div>
    
  )
}

export default Home