import React from 'react';

function TablaUsuarios(props){
    const {usuarios, onDelete, onSelect} = props;
    return (
        <table className="table table-striped">
            <thead>
                <tr className="table-primary">
                    <th scope="col">Usuario</th>
                    <th scope="col">Clave</th>
                    <th scope="col">Rol</th>
                    <th scope="col">Correo</th>
                    <th scope="col">Acciones</th>
                </tr>
            </thead>
            <tbody>
                {usuarios.map((usuario) => {
                    return (<tr key={usuario._id}>
                        <td>{usuario.usuario}</td>
                        <td>{usuario.clave}</td>
                        <td>{usuario.rol}</td>
                        <td>{usuario.persona.correo}</td>
                        <td>
                            <button className='btn btn-danger btn-sm' onClick={()=>onDelete(usuario._id)}>Eliminar</button>
                            <button className='btn btn-secondary btn-sm' onClick={()=>onSelect(usuario)}>Seleccionar</button>
                        </td>
                    </tr>)
                })}
            </tbody>
        </table>
    )
}

export default TablaUsuarios;