import React from 'react'

function Navegador() {
  return (
    <header className="container">
            <nav className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
                <ul className="nav nav-pills">
                    <li className="nav nav-item"><a className="nav-link" href="/">Home</a></li>
                    <li className="nav nav-item"><a className="nav-link" href="/personas">Personas</a></li>
                    <li className="nav nav-item"><a className="nav-link" href="/usuarios">Usuarios</a></li>
                    <li className="nav nav-item"><a className="nav-link" href="/productos">Productos</a></li>
                </ul>
            </nav>
        </header>
  )
}

export default Navegador