import React, { useEffect, useState } from "react";
import { actualizarPersona, agregarPersona, eliminarPersona, obtenerPersonas } from "../../apis/personasApi";
import Navegador from "../generales/Navegador";
import FormPersonas from "./FormPersonas";
import TablaPersonas from "./TablaPersonas";

function PersonasComponent() {
  const [personas, setPersonas] = useState([]);
  const [persona, setPersona] = useState([]);
  const [verLista, setVerLista] = useState(true);
  useEffect(() => {
    obtenerPersonas().then((personas) => setPersonas(personas));
  }, []);

const guardarPersona = (persona) => { 
  console.log(persona._id)
  console.log(persona._id === undefined)
  if(persona._id === undefined)
  {
    console.log("persona")
    setVerLista(true);
    return agregarPersona(persona).then((data) => setPersonas([...personas, data]));
  }else{
    actualizarPersona(persona).then((data)=>{return data})
  }
  
}

const eliminarPersonas = (id) => {
  return eliminarPersona(id).then((data) => {
    if(data.deletedCount === 1){
      setPersonas(personas.filter(persona => persona._id !== id))
    }
  })
};

const mostrarPersona = (persona) => {
  setVerLista(false);
  setPersona(persona);
}

  return <div>
        <Navegador/>
        {!verLista && <div>
          <button className="btn btn-success" onClick={()=>{setVerLista(true)}}>Ver lista de personas</button>
          <FormPersonas onSave={guardarPersona} personaSeleccionada={persona}/>
        </div>}
        {verLista && <div>
          <button className="btn btn-primary" onClick={()=>{setVerLista(false)}}>Crear nueva persona</button>
          <TablaPersonas personas={personas} onDelete={eliminarPersonas} onSelect={mostrarPersona}/>
        </div> }
    </div>;
}

export default PersonasComponent;
