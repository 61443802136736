import React from 'react';

function TablaProductos(props){
    const {productos, onDelete, onSelect} = props;
    return (
        <table className="table table-striped">
            <thead >
                <tr className="table-primary">
                    <th scope="col">Nombre</th>
                    <th scope="col">Código</th>
                    <th scope="col">Unidad de Medida</th>
                    <th scope="col">Valor de Compra</th>
                    <th scope="col">Valor de Venta</th>
                    <th scope="col">Cantidad en inventario</th>
                    <th scope="col">Acciones</th>
                </tr>
            </thead>
            <tbody>
                {productos.map((producto) => {
                    return (<tr key={producto._id}>
                        <td>{producto.nombre}</td>
                        <td>{producto.codigo}</td>
                        <td>{producto.unidadMedida}</td>
                        <td>{producto.valorCompra}</td>
                        <td>{producto.valorVenta}</td>
                        <td>{producto.cantidad}</td>
                        <td>
                            <button className='btn btn-danger btn-sm' onClick={()=>onDelete(producto._id)}>Eliminar</button>
                            <button className='btn btn-secondary btn-sm' onClick={()=>onSelect(producto)}>Seleccionar</button>
                        </td>
                    </tr>)
                })}
            </tbody>
        </table>
    )
}

export default TablaProductos;