import React, {useState, useEffect} from "react";

function FormProductos(props) {
  const {onSave, productoSeleccionado} = props;
    const [producto, setProducto] = useState({
        _id: null, 
        nombre: "", 
        codigo: "", 
        valorCompra: 0, 
        valorVenta: 0,
        cantidad: 0, 
        unidadMedida: "",
        descripcion: ""
    });

    useEffect(() => {
      setProducto(productoSeleccionado)
    }, [productoSeleccionado])
    

   const handleChange = (e) => {
      setProducto({
        ...producto, [e.target.name] : e.target.value
      });
   }; 

   const onSubmit = (e) => {
      e.preventDefault();
      onSave(producto);
      limpiar();
   };

   const limpiar = () => {
    setProducto({
        _id: null, 
        nombre: "", 
        codigo: "", 
        valorCompra: 0, 
        valorVenta: 0,
        cantidad: 0, 
        unidadMedida: "",
        descripcion: ""
    });
   };

  return (
    <div>
      <form onSubmit={onSubmit}>
      <input type="hidden" name="_id" value={producto._id} onChange={(e) => handleChange(e)}/>
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">Nombre:</label>
            <input className="form-control" type="text" name="nombre" value={producto.nombre} onChange={(e) => handleChange(e)}/>
          </div>
          <div className="col-md-6">
            <label className="form-label">Código:</label>
            <input className="form-control" type="text" name="codigo" value={producto.codigo} onChange={(e) => handleChange(e)}/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">Valor de Compra:</label>
            <input className="form-control" type="text" name="valorCompra" value={producto.valorCompra} onChange={(e) => handleChange(e)}/>
          </div>
          <div className="col-md-6">
            <label className="form-label">Valor de Venta:</label>
            <input className="form-control" type="text" name="valorVenta" value={producto.valorVenta} onChange={(e) => handleChange(e)}/>
          </div>
        </div>
        <div className="row">
          <label className="form-label">Unidad de Medida:</label>
          <input className="form-control" type="text" name="unidadMedida" value={producto.unidadMedida} onChange={(e) => handleChange(e)}/>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">Cantidad:</label>
            <input className="form-control" type="text" name="cantidad" value={producto.cantidad} onChange={(e) => handleChange(e)}/>
          </div>
        </div>
        <div>
          <input className="btn btn-primary" type="submit" value="Guardar" />          
        </div>
      </form>
      <button className="btn btn-secondary" onClick={limpiar}>Limpiar</button>
    </div>
  );
}

export default FormProductos;
