import React, { useState, useEffect } from "react";

function FormUsuarios(props) {
  const { onSave, usuarioSeleccionado } = props;
  const [usuario, setUsuario] = useState({
    _id: null,
    usuario: "",
    clave: "",
    rol: "Vendedor",
    persona: {
      idPersona: "",
      correo: "",
    }
  });

  useEffect(() => {
    setUsuario(usuarioSeleccionado);
  }, [usuarioSeleccionado]);

  const handleChange = (e) => {
    setUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePersona = (e) => {
    setUsuario({
      ...usuario,persona:{
      [e.target.name]: e.target.value}
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    onSave(usuario);
    limpiar();
  };

  const limpiar = () => {
    setUsuario({
      _id: null,
      usuario: "",
      clave: "",
      rol: "Vendedor",
      persona: {
        idPersona: "",
        correo: "",
      },
    });
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <input type="hidden" name="_id" value="" />
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">Usuario:</label>
            <input className="form-control" type="text" name="usuario" value={usuario.usuario} onChange={(e) => handleChange(e)}/>
          </div>
          <div className="col-md-6">
            <label className="form-label">Clave:</label>
            <input className="form-control" type="text" name="clave" value={usuario.clave} onChange={(e) => handleChange(e)}/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">Rol:</label>
            <select className="form-select" name="rol" value={usuario.rol} onChange={(e) => handleChange(e)}>
              <option value="Vendedor">Vendedor</option>
              <option value="Administrador">Administrador</option>
              <option value="Cajero">Cajero</option>
            </select>
          </div>
          <div className="col-md-6">
            <label className="form-label">Correo:</label>
            <input className="form-control" type="text" name="correo" value={usuario.persona.correo} onChange={(e) => handleChangePersona(e)}
            />
          </div>
        </div>
        <div>
          <input className="btn btn-primary" type="submit" value="Guardar" />
        </div>
      </form>
      <button className="btn btn-secondary" onClick={limpiar}>
        Limpiar
      </button>
    </div>
  );
}

export default FormUsuarios;
