import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PersonasComponent from '../personas/PersonasComponent'
import ProductosComponent from '../productos/ProductosComponent'
import Login from '../usuarios/Login'
import UsuariosComponent from '../usuarios/UsuariosComponent'
import Home from './Home'

function Router() {
  return (
    <div>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/login' element={<Login/>}/>
                <Route path='/personas' element={<PersonasComponent/>}/>
                <Route path='/usuarios' element={<UsuariosComponent/>}/>
                <Route path='/productos' element={<ProductosComponent/>}/>
            </Routes>
        </BrowserRouter>
    </div>
  )
}

export default Router