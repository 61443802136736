import React, {useState} from "react";
import { login } from "../../apis/usuariosApi";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

function Login() {
    const navegador = useNavigate();
    const [usuario, setUsuario] = useState({
        _id: null,
        usuario: "",
        clave: "",
        rol: "",
        persona: {
          idPersona: "",
          correo: "",
        }
      });

    async function onSubmit (e) {
        e.preventDefault();
        const data = await login(usuario);
        if(data === null){
          localStorage.clear();
            swal({
                title: "Error", 
                text: "Usuario o clave inválido, favor verificar", 
                icon: "error"
            })
        }else{
            localStorage.setItem("usuarioActivo", JSON.stringify(data));
            navegador("/");
        }
    };

    const handleChange = (e) => {
        setUsuario({
          ...usuario,
          [e.target.name]: e.target.value,
        });
      };

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <form onSubmit={onSubmit}>
          <input type="text" id="login" className="fadeIn second" name="usuario" placeholder="usuario" value={usuario.usuario} onChange={(e) => handleChange(e)}/>
          <input type="text" id="password" className="fadeIn third" name="clave" placeholder="clave" value={usuario.clave} onChange={(e) => handleChange(e)}/>
          <input type="submit" className="fadeIn fourth" defaultValue="Log In" />
        </form>
        <div id="formFooter">
          <a className="underlineHover" href="/usuarios">
            Registrate...
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;
