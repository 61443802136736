import React, {useState, useEffect} from "react";

function FormPerson(props) {
  const {setPerson, getPerson} = props;
    const [persona, setPersona] = useState({
        _id: null, 
        nombres: "", 
        apellidos: "", 
        tipDoc: "CC", 
        numDoc: "",
        direccion: "", 
        telefono: "",
        correo: ""
    });

    if(persona === null )
      setPersona({
        _id: "", 
        nombres: "", 
        apellidos: "", 
        tipDoc: "CC", 
        numDoc: "",
        direccion: "", 
        telefono: "",
        correo: ""
    })
    
    useEffect(() => {
      console.log(setPerson)
      if(setPerson)
        setPersona(setPerson)
    }, [setPerson])
    
       
   const handleChange = (e) => {
      setPersona({
        ...persona, [e.target.name] : e.target.value
      });
      getPerson(persona)
   }; 

  return (
    <div>
      <form>
      <input type="hidden" name="_id" value={persona._id} onChange={(e) => handleChange(e)}/>
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">Nombres:</label>
            <input className="form-control" type="text" name="nombres" value={persona.nombres} onChange={(e) => handleChange(e)}/>
          </div>
          <div className="col-md-6">
            <label className="form-label">Apellidos:</label>
            <input className="form-control" type="text" name="apellidos" value={persona.apellidos} onChange={(e) => handleChange(e)}/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">Tipo de Documento:</label>
            <select className="form-select" name="tipDoc" value={persona.tipDoc} onChange={(e) => handleChange(e)}>
              <option value="CC">CC - Cédula de Ciudadanía</option>
              <option value="CE">CE - Cédula de Extranjería</option>
              <option value="PAS">PAS - Pasaporte</option>
            </select>
          </div>
          <div className="col-md-6">
            <label className="form-label">Número de documento:</label>
            <input className="form-control" type="text" name="numDoc" value={persona.numDoc} onChange={(e) => handleChange(e)}/>
          </div>
        </div>
        <div className="row">
          <label className="form-label">Dirección:</label>
          <input className="form-control" type="text" name="direccion" value={persona.direccion} onChange={(e) => handleChange(e)}/>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">Teléfono:</label>
            <input className="form-control" type="text" name="telefono" value={persona.telefono} onChange={(e) => handleChange(e)}/>
          </div>
          <div className="col-md-6">
            <label className="form-label">Correo:</label>
            <input className="form-control" type="text" name="correo" value={persona.correo} onChange={(e) => handleChange(e)}/>
          </div>
        </div>
      </form>
    </div>
  );
}

export default FormPerson;
