import React, { useEffect, useState } from "react";
import { actualizarProducto, agregarProducto, eliminarProducto, obtenerProductos } from "../../apis/productosApi";
import Navegador from "../generales/Navegador";
import FormProductos from "./FormProductos";
import TablaProductos from "./TablaProductos";

function ProductosComponent() {
  const [productos, setProductos] = useState([]);
  const [producto, setProducto] = useState([]);
  const [verLista, setVerLista] = useState(true);
  useEffect(() => {
    obtenerProductos().then((productos) => setProductos(productos));
  }, []);

const guardarProducto = (producto) => { 
  console.log(producto)
  if(producto._id === undefined)
  {
    console.log("guardar nuevo")
    setVerLista(true);
    return agregarProducto(producto).then((data) => setProductos([...productos, data]));
  }else{
    console.log("actualizar")
    actualizarProducto(producto).then((data)=>{return data})
  }
  
}

const eliminarProductos = (id) => {
  return eliminarProducto(id).then((data) => {
    if(data.deletedCount === 1){
      setProductos(productos.filter(producto => producto._id !== id))
    }
  })
};

const mostrarProducto = (producto) => {
  setVerLista(false);
  setProducto(producto);
}

  return <div>
        <Navegador/>
        {!verLista && <div>
          <button className="btn btn-success" onClick={()=>{setVerLista(true)}}>Ver lista de productos</button>
          <FormProductos onSave={guardarProducto} productoSeleccionado={producto}/>
        </div>}
        {verLista && <div>
          <button className="btn btn-primary" onClick={()=>{setVerLista(false)}}>Crear nuevo producto</button>
          <TablaProductos productos={productos} onDelete={eliminarProductos} onSelect={mostrarProducto}/>
        </div> }
    </div>;
}

export default ProductosComponent;
