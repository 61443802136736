import React, { useEffect, useState } from "react";
import { actualizarUsuario, agregarUsuario, obtenerUsuarios } from "../../apis/usuariosApi";
import Navegador from "../generales/Navegador";
import FormUsuarios from "./FormUsuarios";
import TablaUsuarios from "./TablaUsuarios";

function UsuariosComponent() {
  const [usuarios, setUsuarios] = useState([]);
  const [usuario, setUsuario] = useState([]);
  const [verLista, setVerLista] = useState(true);

  const usuarioActivo = JSON.parse(localStorage.getItem("usuarioActivo"));
  
  const verFormulario = ()=>{
    setVerLista(false); 
    setUsuario({
    _id: null,
    usuario: "",
    clave: "",
    rol: "Vendedor",
    persona: {
      idPersona: "",
      correo: "",
    }});
  };
  
  if((usuarioActivo && usuarioActivo.rol !== ""))
    verFormulario();
  useEffect(() => {
    obtenerUsuarios().then((usuarios) => setUsuarios(usuarios));
  }, []);

  const guardarUsuario = (usuario) => { 
    if(usuario._id === undefined)
    {
      console.log("nuevo")
      console.log(usuario)
      setVerLista(true);
      return agregarUsuario(usuario)
      .then((data) => setUsuarios([...usuarios, data]));
    }else{
      actualizarUsuario(usuario).then((data)=>{return data})
    }
    
  }

  const eliminarUsuarios = (id) => {
    return eliminarUsuarios(id).then((data) => {
      if(data.deletedCount === 1){
        setUsuarios(usuarios.filter(usuario => usuario._id !== id))
      }
    })
  };

  const mostrarUsuario = (usuario) => {
    setVerLista(false);
    setUsuario(usuario);
  }

  

  return (
    <div>
      {(usuarioActivo && usuarioActivo.rol !== "") && <Navegador/>}
      {!verLista && <div>
        <button className="btn btn-success" onClick={()=>{setVerLista(true)}}>Ver lista de usuarios</button>
        <FormUsuarios onSave={guardarUsuario} usuarioSeleccionado={usuario}/>
      </div>}
      {verLista && <div>
        <button className="btn btn-primary" onClick={verFormulario}>Crear nuevo usuario</button>
        <TablaUsuarios usuarios={usuarios}  onDelete={eliminarUsuarios} onSelect={mostrarUsuario}/>
      </div> }
    </div>
  );
}

export default UsuariosComponent;
