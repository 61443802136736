import React from 'react';
import Router from './components/generales/Router';

function App() {
  return (
    <div className="container">
      {<Router/>}
    </div>
  );
}

export default App;
