import React from 'react';

function TablaPersonas(props){
    const {personas, onDelete, onSelect} = props;
    return (
        <table className="table table-striped">
            <thead >
                <tr className="table-primary">
                    <th scope="col">Nombres</th>
                    <th scope="col">Apellidos</th>
                    <th scope="col">Tipo de Documento</th>
                    <th scope="col">Número de Documento</th>
                    <th scope="col">Dirección</th>
                    <th scope="col">Teléfono</th>
                    <th scope="col">Correo</th>
                    <th scope="col">Acciones</th>
                </tr>
            </thead>
            <tbody>
                {personas.map((persona) => {
                    return (<tr key={persona._id}>
                        <td>{persona.nombres}</td>
                        <td>{persona.apellidos}</td>
                        <td>{persona.tipDoc}</td>
                        <td>{persona.numDoc}</td>
                        <td>{persona.direccion}</td>
                        <td>{persona.telefono}</td>
                        <td>{persona.correo}</td>
                        <td>
                            <button className='btn btn-danger btn-sm' onClick={()=>onDelete(persona._id)}>Eliminar</button>
                            <button className='btn btn-secondary btn-sm' onClick={()=>onSelect(persona)}>Seleccionar</button>
                        </td>
                    </tr>)
                })}
            </tbody>
        </table>
    )
}

export default TablaPersonas;